import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SellIcon from "@mui/icons-material/Sell";
import { PhoneIphone } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// const images = [
//   {
//     original: "assets/gallery/image1.jpg",
//     thumbnail: "assets/thumbnails/image1_thumb.jpg",
//   },
//   {
//     original: "assets/gallery/image2.jpg",
//     thumbnail: "assets/thumbnails/image2_thumb.jpg",
//   },
// ];

const drawerPages = [
  {
    name: "Preturi",
    icon: <SellIcon />,
    id: "preturi",
  },
  {
    name: "Contact",
    icon: <PhoneIphone />,
    id: "contact",
  },
];

const prices = [
  {
    packagename: "CU SAU FARA LIVRARE",
    packagePrice: "13",
    background: "#d5d5d5",
    textColor: "black",
    comingSoon: false,
    description:
      "Pentru un pret foarte avantajos, poti aduce chiar tu covorul la spalatoria Best Clean, in unul din centrele noastre din Horezu, Polovragi sau Otesani, urmand sa il ridici curat si parfumat." +
      " Desigur, pentru un plus de confort iti putem oferi si livrare, gratuit pentru minim 5 mp in Horezu si 10 mp in localitatile limitrofe pe o raza de 20 KM 🚚",
  },
];

const equipments = [
  {
    name: "Desprafuitor",
    picture: "assets/equipment/desprafuitor.webp",
    description: "Asigura eliminarea prafului de pe covoare",
  },
  {
    name: "Masina automata de spalat",
    picture: "assets/equipment/masina_de_spalat.webp",
    description:
      "Indeparteaza petele si murdaria cu ajutorul periilor si detergentilor speciali",
  },
  {
    name: "Centrifuga",
    picture: "assets/equipment/centrifuga.webp",
    description: "Elimina apa din covor in proportie de 95%",
  },
  {
    name: "Dezumidificator",
    picture: "assets/equipment/dezumidificator.webp",
    description: "Asigura uscarea eficienta a covorului în interior",
  },
];

const steps = [
  {
    step: 1,
    stepTitle: "Analiza covorului",
    stepDescription: [
      "Se identifica tesatura covorului, lungimea firelor, materialul si starea fizică",
      "Se stabileste temperatura si solutia potrivita pentru indepartarea petelor si a murdariei",
      "Se masoară si se eticheteaza covoarele",
    ],
  },
  {
    step: 2,
    stepTitle: "Desprafuirea mecanizata",
    stepDescription: [
      "Consta in eliminarea mecanizata a prafului, a nisipului si a murdariei de pe intreaga suprafata a covorului",
      "Aspirarea prafului, parului, scamelor si a nisipului se realizeaza automat in saci speciali, fara a fi eliberate in atmosfera",
    ],
  },
  {
    step: 3,
    stepTitle: "Spalarea automata a covoarelor",
    stepDescription: [
      "In aceasta faza se realizeaza spalarea propriu-zisa a covorului, care trece prin mai multe etape: " +
        "inmuiere, spalare prin periaj cu samponare abundenta si clatire cu jet de apa sub presiune",
    ],
  },
  {
    step: 4,
    stepTitle: "Centrifugarea - stoarcerea",
    stepDescription: [
      "Consta in eliminarea apei din covor în proportie de 95% după o clatire suplimentara",
    ],
  },
  {
    step: 5,
    stepTitle: "Uscarea covoarelor",
    stepDescription: [
      "Dupa centrifugare, covoarele sunt introduse in camera de uscare, dotata cu un dezumidificator, instalatie de filtrare a aerului" +
        "si ventilatie, toate controlate automat, astfel ca un covor poate fi uscat intr-un interval de 9-12 ore",
    ],
  },
  {
    step: 6,
    stepTitle: "Periaj - verificare",
    stepDescription: [
      "Inainte de a fi parfumate si ambalate, covoarele sunt periate in totalitate pentru eliminarea eventualelor scame sau fire de par",
    ],
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  strong: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

theme.typography.h1 = {
  fontFamily: ["Montserrat", "sans-serif"].join(","),
  fontSize: "0.8rem",

  "@media (min-width:375px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <div className="app-wrapper">
          <AppBar
            position="fixed"
            style={{
              opacity: "0.95",
              backgroundColor: "#157b9f",
            }}
          >
            <Container className="header-container">
              <Toolbar
                disableGutters
                style={{
                  textAlign: "center",
                  minHeight: "85px",
                }}
              >
                <AppDrawer />
                <Header />
                <ContacList style={{ float: "right" }} type="header" />
              </Toolbar>
            </Container>
          </AppBar>
          <Box sx={{ mt: { xs: "110px", md: "110px", lg: "110px" } }}>
            <Discount />
            <WelcomeCard />
            <Equipment />
            <Steps />
          </Box>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Footer />
        </Box>
      </React.Fragment>
    </ThemeProvider>
  );
}

function AppDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const [open, setOpen] = React.useState(false);

  const [id, setId] = React.useState("");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function handlePageClick(type, anchor, open) {
    setId((id) => type);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const list = (anchor) => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {drawerPages.map((page, index) =>
          index < 3 ? (
            <ListItem
              key={page.name}
              disablePadding
              onClick={() => handlePageClick(page.id, anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <ListItemButton>
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
          ) : (
            <Box key={page.name}>
              <Divider />
              <ListItem
                disablePadding
                onClick={() => handlePageClick(page.id, anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                <ListItemButton>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            </Box>
          )
        )}
        <ModalInfoPreturi handleClose={handleClose} open={open} type={id} />
        <ModalInfoContact handleClose={handleClose} open={open} type={id} />
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            color="inherit"
            name="hamburger-menu-button"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

function ModalInfoPreturi(props) {
  return (
    props.type === "preturi" && (
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        sx={{ width: "auto" }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#157b9f",
            color: "white",
            fontWeight: 700,
          }}
          id="customized-dialog-title"
        >
          Preturi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {prices.map((price) => (
            <Box
              key={price.packagename}
              sx={{ display: "flex", position: "relative" }}
            >
              <div
                style={{
                  display: { xs: "block", md: "flex" },
                  backgroundColor: price.background,
                  width: "100%",
                  padding: "25px 20px 25px 20px",
                  color: price.textColor,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    key={price.packagePrice}
                    sx={{
                      // mr: 10,
                      fontSize: "75px",
                      fontWeight: 700,
                      lineHeight: 1,
                      // mb: 1,
                    }}
                  >
                    {price.packagePrice}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ ml: { xs: "1px", md: "8px" }, mt: 3 }}
                  >
                    lei/mp
                  </Typography>
                </div>
                {/* <CustomDivider dividercolor={price.textColor} /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Typography
                      variant="h6"
                      fontWeight="700"
                      style={{ lineHeight: "1.2" }}
                    >
                      {price.packagename.toUpperCase()}
                    </Typography>
                  </div>
                  <CustomDivider dividercolor={price.textColor} />
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: "15px",
                      lineHeight: "1.5",
                    }}
                  >
                    {price.description}
                  </Typography>
                </div>
              </div>
            </Box>
          ))}
        </DialogContent>
      </BootstrapDialog>
    )
  );
}

function ModalInfoContact(props) {
  return (
    props.type === "contact" && (
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        sx={{ width: "auto" }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: "#157b9f",
            color: "white",
            fontWeight: 700,
          }}
          id="customized-dialog-title"
        >
          Contact
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ mt: 1, mb: 1, textAlign: "center", color: "#717171" }}
        >
          <Typography>
            <strong style={{ color: "black" }}>Best Clean</strong> te asteapta
            cu echipamente de ultima generatie in localitatea{" "}
            <strong>
              {" "}
              <a
                href="https://maps.app.goo.gl/c1g4CUc54RVj6fno9"
                style={{ textDecoration: "none", color: "#157b9f" }}
                target="_blank"
                rel="noreferrer noopener"
              >
                Horezu, strada Constantin Brancoveanu nr. 41
              </a>
            </strong>
            <span>
              {" "}
              sau in centrele noastre de colectare din{" "}
              <strong>Polovragi</strong> si
              <strong> Otesani</strong>
            </span>
          </Typography>
          <CustomDivider dividercolor="#717171" />
          <Box>
            <Typography color="#717171">
              Deschis <strong>Luni - Vineri</strong>, orele{" "}
              <strong>09:00 - 17:00</strong>
            </Typography>
            <Typography color="#717171">
              Pentru informatii, ne poti contacta la numǎrul de telefon{" "}
              <strong>
                <a
                  href="tel:0773966411"
                  style={{ textDecoration: "none", color: "#157b9f" }}
                >
                  {" "}
                  0773966411
                </a>
              </strong>
            </Typography>
          </Box>
          <CustomDivider dividercolor="#717171" />
          <Typography sx={{ mt: 2 }}>
            Viziteaza si paginile noastre de social media pentru a fi la curent
            cu noutatile spalatoriei de covoare{" "}
            <strong>Best Clean Horezu</strong>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: " #157b9f",
            }}
          >
            <Contact
              type="facebook"
              location="contactDialog"
              text="Facebook"
              icon={<FacebookIcon />}
            />
            <Contact
              type="instagram"
              location="contactDialog"
              text="Instagram"
              icon={<InstagramIcon />}
            />
          </Box>
          {/* <ContacList type="modal" style={{ color: "white" }} /> */}
        </DialogContent>
      </BootstrapDialog>
    )
  );
}

function Header() {
  return (
    <div className="header-text" style={{ wordBreak: "break-word" }}>
      <LocalLaundryServiceIcon
        sx={{
          mr: 1,
          ml: { xs: -5, md: 15, lg: 15 },
          fontSize: { xs: "13px", md: "25px", lg: "25px" },
          whiteSpace: "initial",
          wordBreak: "break-word",
          display: { xs: "none", md: "initial" },
          verticalAlign: "middle",
        }}
      />
      <Typography
        variant="h1"
        component="h1"
        sx={{
          display: "inline-flex",
          ml: { xs: -5, md: 2, lg: 0 },
          fontWeight: 700,
          letterSpacing: ".1rem",
          color: "inherit",
          maxWidth: { xs: "85%", md: "100%", lg: "100%" },
          whiteSpace: "initial",
          wordBreak: "break-word",
          verticalAlign: "middle",
        }}
      >
        Best Clean - Spalatorie Covoare Horezu
      </Typography>
    </div>
  );
}

function ContacList({ type }) {
  const isTypeFooter = type === "footer";
  return (
    <List
      dense={true}
      style={{ marginLeft: "auto" }}
      sx={
        isTypeFooter
          ? { display: { xs: "none", md: "flex" } }
          : { display: { xs: "none", md: "block" } }
      }
    >
      <Contact type="telefon" text="0773966411" icon={<PhoneIcon />} />
      {/* {isTypeFooter && (
        <Contact type="facebook" text="Facebook" icon={<FacebookIcon />} />
      )}
      {isTypeFooter && (
        <Contact type="instagram" text="Instagram" icon={<InstagramIcon />} />
      )} */}
      {isTypeFooter && (
        <>
          <Contact type="facebook" text="Facebook" icon={<FacebookIcon />} />
          <Contact type="instagram" text="Instagram" icon={<InstagramIcon />} />
        </>
      )}
      {!isTypeFooter && (
        <ListItem>
          <Typography
            sx={{ fontSize: "14px", textAlign: "end", minWidth: "159px" }}
          >
            Luni-Vineri 09:00-17:00
          </Typography>
        </ListItem>
      )}
    </List>
  );
}

function Contact(props) {
  function handleClick(type) {
    if (type === "facebook") {
      window.open(
        "https://www.facebook.com/spalatoria.bestclean.horezu",
        "_blank",
        "noreferrer"
      );
    } else if (type === "instagram") {
      window.open(
        "https://www.instagram.com/bestcleanhorezu/",
        "_blank",
        "noreferrer"
      );
    } else {
      window.open("tel: 0773966411", "_self");
    }
  }

  return (
    <ListItem
      sx={{
        display: "flex",
        justifyContent: "end",
        padding: "0 15px 0 0 !important",
      }}
      className={`${
        props.location === "contactDialog" ? "contact-dialog" : ""
      }`}
    >
      <Button
        color="inherit"
        size="small"
        onClick={() => handleClick(props.type)}
        startIcon={props.icon}
        sx={{ padding: "0 !important" }}
      >
        <ListItemText primary={props.text} style={{ color: "inherit" }} />
      </Button>
    </ListItem>
  );
}

function Discount() {
  return (
    <Card
      sx={{
        // width: { lg: "70%" },
        width: { xs: "85%", md: "80%", lg: "80%" },

        height: "auto",
        padding: "20px 10px",
        position: "relative",
        // margin: "3% auto 0 auto",
        // mt: { xs: "110px", md: "110px", lg: "110px" },
        ml: "auto",
        mr: "auto",
        opacity: 0.9,
      }}
    >
      <CardContent>
        <img
          src="assets/flyer.webp"
          alt="Flyer offer"
          style={{
            width: "100%",
          }}
        ></img>
        <h6
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "700",
            fontFamily: ["Montserrat", "sans-serif"].join(","),
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          SPALATORIA DE COVOARE BEST CLEAN VINE IN SPRIJINUL CLIENTILOR CU UN
          NOU SERVICIU! 🤩
        </h6>
        <CustomDivider dividercolor="#717171" />
        <p
          style={{
            // display: "flex",
            justifyContent: "center",
            color: "#059f34",
            textAlign: "center",
            fontWeight: "600",
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          🚚 Asiguram un plus de confort pentru clientii nostri prin
          introducerea serviciului de colectare si livrare a covoarelor la
          domiciliu 🏡
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontSize: "17px",
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          🔥 IAR ASTA NU E TOT! 🔥
        </p>
        <CustomDivider dividercolor="#717171" />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          Pentru a imbunatati procesele de comunicare si spalare, vom deschide
          doua noi centre de preluare pentru clientii care doresc sa ne aduca
          personal covoarele!
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          Astfel, din 20 Februarie 2023, poti aduce covoarele si la centrele
          noastre din Polovragi si Otesani 📍
        </p>
        <p
          style={{
            color: "#717171",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          Iti oferim{" "}
          <span style={{ color: "#059f34", textDecoration: "underline" }}>
            transport GRATUIT
          </span>{" "}
          pentru dimensiuni de peste 5 mp in Horezu si 10 mp in localitatile
          limitrofe 🚚
        </p>
        <CustomDivider dividercolor="#717171" />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          🌟 Reda stralucirea si culorile covorului tau doar la spalatoria Best
          Clean prin servicii profesionale de curatatorie-spaltorie, la cel mai
          competitiv pret din Horezu si localitatile adiacente, doar 13 lei/mp!
        </p>
        <p
          style={{
            display: "flex",
            fontStyle: "italic",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontSize: "10px",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          *Pentru covoarele cu suprafata de 1 mp, se percepe taxarea unui metru
          patrat
        </p>
      </CardContent>
    </Card>
  );
}

function WelcomeCard() {
  return (
    <Card
      sx={{
        // width: { lg: "70%" },
        width: { xs: "85%", md: "80%", lg: "80%" },

        height: "auto",
        padding: "20px 10px",
        position: "relative",
        // margin: "3% auto 0 auto",
        mt: 2,
        // mt: { xs: "110px", md: "110px", lg: "110px" },
        ml: "auto",
        mr: "auto",
        opacity: 0.9,
      }}
    >
      <CardContent>
        <h6
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontWeight: "700",
            fontFamily: ["Montserrat", "sans-serif"].join(","),
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          SPALATORIA DE COVOARE BEST CLEAN
        </h6>
        <CustomDivider dividercolor="#717171" />
        <p
          style={{
            // display: "flex",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontWeight: "600",
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          Best Clean este o spalatorie de covoare profesionala in localitatea
          <strong>
            {" "}
            <a
              href="https://maps.app.goo.gl/c1g4CUc54RVj6fno9"
              style={{ textDecoration: "none", color: "#157b9f" }}
              target="_blank"
              rel="noreferrer noopener"
            >
              Horezu, pe strada Constantin Brancoveanu, nr. 41
            </a>
          </strong>
          . Preturile noastre sunt foarte accesibile, iar serviciile sunt de cea
          mai buna calitate. Folosim echipamente de ultima generatie si
          detergenti ecologici, astfel incat putem reda covoarelor tale
          culorile, curatenia si aspectul practic nou.
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontWeight: "600",
            lineHeight: 1.5,
            fontFamily: ["Montserrat", "sans-serif"].join(","),
          }}
        >
          Ca sa te convingi, te asteptam pe la noi de Luni pana Vineri intre
          orele 9 si 17!
        </p>
      </CardContent>
    </Card>
  );
}

function Equipment() {
  return (
    <Card
      sx={{
        // width: { lg: "70%" },
        width: { xs: "85%", md: "80%", lg: "80%" },

        height: "auto",
        padding: "20px 10px",
        position: "relative",
        // margin: "3% auto 0 auto",
        // mt: { xs: "110px", md: "110px", lg: "110px" },
        mt: 2,
        ml: "auto",
        mr: "auto",
        opacity: 0.9,
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          fontWeight="700"
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          LASA COVORUL IN SEAMA NOASTRA PENTRU O CASA CURATA!
        </Typography>
        <Typography
          fontWeight="600"
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            textAlign: "center",
            fontStyle: "italic",
            mt: 2,
          }}
        >
          Indepartam chiar si petele dificile si mirosurile neplacute, astfel
          incat il vei primi inapoi curat si parfumat, totul la un tarif mic de
          numai 13 lei/mp!
        </Typography>
        <CustomDivider dividercolor="#717171" />
        <Typography
          fontWeight="600"
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            marginBottom: "50px",
            textAlign: "center",
          }}
        >
          La Best Clean, spalarea profesionala a covoarelor se efectueaza
          mecanic, cu echipamente moderne
        </Typography>

        {equipments.map((equipment) => (
          <Box
            key={equipment.name}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "80%" },
              // minWidth: "250px",
              justifyContent: "center",
              // alignItems: "center",
              margin: "10px auto",
            }}
          >
            <Box>
              <Box
                sx={{
                  // background: "#157b9f",
                  color: "#717171",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={equipment.picture}
                  alt={equipment.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                ></img>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  sx={{ mt: { xs: "10px", md: "20px" }, color: "black" }}
                >
                  {equipment.name}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  {equipment.description}
                </Typography>
                <CustomDivider dividercolor="white" />
              </Box>
            </Box>
          </Box>
        ))}
        {/* <Box
          className="image-gallery"
          sx={{
            mt: 5,
            width: { xs: "100%", md: "70%" },
          }}
        >
          <ImageGallery
            items={images}
            originalHeight="100"
            originalWidth="100"
            showPlayButton={false}
            showBullets={true}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
}

function Steps() {
  return (
    <Card
      sx={{
        // width: { lg: "70%" },
        width: { xs: "85%", md: "80%", lg: "80%" },

        height: "auto",
        padding: "20px 10px",
        position: "relative",
        // margin: "3% auto 0 auto",
        mt: "20px",
        ml: "auto",
        mr: "auto",
        opacity: 0.9,
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          fontWeight="700"
          // color="#3f3f3f"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          CUM PROCEDAM?
        </Typography>
        <CustomDivider dividercolor="#717171" />
        <Typography
          fontWeight="600"
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#717171",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          De la un covor murdar la unul impecabil sunt doar cativa pasi!
        </Typography>
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "80%" },
              // minWidth: "250px",
              justifyContent: "center",
              // alignItems: "center",
              margin: "20px auto",
            }}
          >
            <Box
              sx={{
                // background: "#157b9f",
                color: "#717171",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // width: "100%",
                }}
              >
                <div className="step-number-container">
                  <Box className="step-number">
                    <Typography variant="h5" fontWeight="400">
                      {step.step}
                    </Typography>
                  </Box>
                </div>
              </Box>
              <div style={{ marginLeft: 25 }}>
                <Typography
                  sx={{
                    // marginLeft: "15px",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  {step.stepTitle.toUpperCase()}
                </Typography>
                {step.stepDescription.map((desc, index) => (
                  <Typography
                    key={desc}
                    style={{
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "13px",
                    }}
                  >
                    {desc}
                  </Typography>
                ))}
              </div>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}

function CustomDivider({ dividercolor }) {
  return (
    <Divider
      variant="inset"
      sx={{
        margin: { xs: "10px auto", md: "25px auto" },
        width: "5%",
        backgroundColor: dividercolor,
      }}
    />
  );
}

function Footer() {
  return (
    <Box
      sx={{
        marginTop: "30px",
        opacity: "0.95",
      }}
      component="footer"
    >
      <footer className="footer">
        <Typography
          sx={{
            ml: 2,
            fontSize: "13px",
            fontWeight: "700",
            // margin: { xs: "0 auto", md: "0 0 0 15px" },
          }}
        >
          Best Clean - Spalatorie Covoare Horezu
        </Typography>
        <ContacList type="footer" />
        <Box
          sx={{
            display: { xs: "flex", md: "none", lg: "none" },
            ml: "auto",
            mr: "10px",
            fontSize: "13px",
            fontWeight: 700,
          }}
        >
          <a
            href="tel:0773966411"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            {" "}
            0773966411
          </a>
        </Box>
      </footer>
    </Box>
  );
}

export default App;
